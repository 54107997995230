import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import SubTitle2 from '../Typography/SubTitle2';
import breakpoints from '../Theme/breakpoints';

const ListItem = styled.li`
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  color: var(--black-medium);
`;

const StyledSubtitle = styled(SubTitle2)`
  && {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    line-height: 12px;
    font-weight: normal;
  }
  color: var(--black-medium);

  display: inline-block;
  padding: 4px 0;
  
  a, span {
    color: var(--black-medium);
    display: inline-block;
  }

  a:hover {
    color: var(--black-high);
  }
`;

const TruncatedListItem = styled.li`
  width: 20%;
  height: 20px;
  display: inline-block;
  vertical-align: middle;

  @media only screen and (min-width: ${breakpoints.xssm}px) {
    width: 38%;
  }
`;

const Separator = styled.li`
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px;
  font-family: var(--font-secondary);
  color: var(--grey-light);
  position: relative;
  top: 1px;

  :before {
   content: '/';
  }
`;

const StyledUl = styled.ul`
  padding: 0;
  margin: 4px 0 0 0;
  width: 100%;
  height: 20px;
  overflow: hidden;
  position: relative;
  display: flex;
  :before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 1rem;
    height: 2rem;
    background-color: white;
    right: 0;
  }
`;

const Breadcrumb = ({ children }: ComponentProperties) => (
  <ListItem>
    <StyledSubtitle tag="span">
      {children}
    </StyledSubtitle>
  </ListItem>
);

const TruncatedBreadcrumb = ({ children }: ComponentProperties) => (
  <TruncatedListItem>
    <StyledSubtitle tag="span">
      {children}
    </StyledSubtitle>
  </TruncatedListItem>
);

const breadcrumbsPropertyTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
  ).isRequired,
  className: PropTypes.string,
};

type BreadcrumbsProperties = InferProps<typeof breadcrumbsPropertyTypes> & {
  className?: string;
};

const Breadcrumbs = ({
  crumbs, className,
}: BreadcrumbsProperties) => (crumbs.length > 0 ? (
  <StyledUl className={className}>
    {crumbs.map((crumb, index, array) => {
      const keyIndex = index * 2;
      return array.length - 1 === index
        ? (
          <TruncatedBreadcrumb key={keyIndex}>
            {crumb}
          </TruncatedBreadcrumb>
        )
        : (
          <React.Fragment key={keyIndex}>
            <Breadcrumb>
              {crumb}
            </Breadcrumb>
            <Separator key={keyIndex + 1} />
          </React.Fragment>
        );
    })}
  </StyledUl>
// eslint-disable-next-line unicorn/no-null
) : null);

Breadcrumb.propTypes = {
  children: PropTypes.node,
};

TruncatedBreadcrumb.propTypes = {
  children: PropTypes.node,
};

Breadcrumbs.propTypes = breadcrumbsPropertyTypes;

export { Breadcrumbs, Separator };
